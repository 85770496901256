<style scoped lang="scss">
  .page-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;

    @include media(max, md) {
      @include padding-x(15px);
    }

    .da-technology, .copyright-address {
      border-radius: 8px;
      background: rgba(255, 255, 255, 0.70);
      padding: 6px 10px;
      display: flex;
      align-items: center;
      white-space: nowrap;
      color: rgba(#191919, .7);
    }

    .da-technology {
      ::v-deep a {
        border-bottom: 1px solid rgba(#191919, .15);
        color: currentColor;

        &:hover {
          text-decoration: none;
        }
      }

      .icon {
        margin-left: 4px;
        font-size: 16px;
      }
    }

    .copyright-address {
      text-align: center;

      .address {
        padding-left: 7px;
        margin-left: 7px;
        border-left: 1px solid rgba(#191919, .15);
      }

      @include media(max, md) {
        white-space: normal;
        flex-direction: column;

        .address {
          padding-left: 0;
          margin-left: 0;
          margin-top: 4px;
          border-left: none;
        }
      }
    }
  }
</style>

<template>
  <div class="page-footer">
    <div class="da-technology">
      <div class="text" v-html="technologyText"></div>
      <icon name="social-donationalerts"/>
    </div>
    <div class="copyright-address">
      <div class="copyright">{{ copyrightText }}</div>
      <div class="address">{{ addressText }}</div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PageFooter',
  computed: {
    technologyText() {
      return this.$t('global.app.technology', {
        link: '<a target="_blank" href="/">DonationAlerts</a>',
      })
    },

    copyrightText() {
      return `© ${new Date().getFullYear()} CEBC B. V.`
    },

    addressText() {
      return 'Prof. J.H. Bavincklaan 7, Amstelveen, 1183 AT, the Netherlands'
    },
  },
}
</script>
